import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');
import QForm from "./QForm";


export default class Services extends React.Component {


  render() {
  return (
    <View style={styles.outer}>
    <ScrollView style={{width:'100%'}}>
      <View style={styles.container2}>
      <View style={styles.container3}>
      <Image
        style={{resizeMode: 'contain', width:'40%', height:'100%', marginRight:50}}
        source={images['DeviceChart']}/>
  <Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
Hardware Services
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
We can help with any PC hardware that you are having difficuly with, including installation and compatability issues. As well, complete disassemby and reassembling of all PC related
hardware if your looking to move locations or adding any new hardware to your current setup. Finally, we may be able to provide a hardware solution to a problem or inconcenience that you are experiencing.
{"\n"}{"\n"}
<View style={{flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
<Text style={styles.TableItem}>{'\u2022'} Internal PC components</Text>
<Text style={styles.TableItem}>{'\u2022'} External PC components / Peripherials</Text>
<Text style={styles.TableItem}>{'\u2022'} PC Hardware Design & Creation</Text>
<Text style={styles.TableItem}>{'\u2022'} Mini-PC/Raspberry Pi Hardware Design & Creation</Text>
</View>
{"\n"}{"\n"}
Be confident in knowing that all our hardware services come with a guarantee that we will attempt to solve any issues that may arise and inform you about any better options.
</Text>
</Text>
    </View>
    </View>
    <View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:80, }}>
    <View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
    </View>
    <View style={styles.container2}>
    <View style={styles.container3}>
<Text style={{width:'50%', margin:20, textAlign: 'left', fontSize:22, fontWeight:'bold',}}>
Software Services
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
We are able to provide assistance with the installation of software, and help with any issues that may arise, including driver issues and compatability issues.
{"\n"}{"\n"}
We can also help the automation of certain tasks performed on your PC that will help you free up sometime to do whatever you want. As well as create custom scripts
that can be used to create simple IOT devices or internal devices controlled via proximity connections, such as IR, RF, and Bluetooth.
{"\n"}{"\n"}
{"\n"}{"\n"}
</Text>
</Text>
<Image
  style={{resizeMode: 'contain', width:'40%', height:'75%', marginLeft:50}}
  source={images['Consult']}/>
</View>
</View>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:80, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
<View style={styles.container2}>
<View style={styles.container3}>
<Image
  style={{resizeMode: 'contain', width:'40%', height:'100%', marginRight:50}}
  source={images['Coding']}/>
<Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
Other Services
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
Some other services that we provide are listed below. We are currently setting up a Card Printing/ID Printing Service which will allow us to provide custom ID cards
for your business or for any event that requires tracking of attendess or for fun and games.
{"\n"}{"\n"}
<View style={{flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
<Text style={styles.TableItem}>{'\u2022'}  Website Design & Building / Graphic Design</Text>
<Text style={styles.TableItem}>{'\u2022'}  Card Printing Services</Text>
<Text style={styles.TableItem}>{'\u2022'}  Custom Javascript/Python Scripts</Text>
<Text style={styles.TableItem}>{'\u2022'}  Consulting</Text>
</View>
{"\n"}{"\n"}
We can also provide Website Design and Graphic Design for your business, and can provide custom designs based on your brand. This could include basic consulting services
to point you in the right direction or complete services that incorporates your logo and colour scheme on the web and physical products, i.e. branded merchandise.
</Text>
</Text>
</View>
</View>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:120, }}/>

      </ScrollView>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    margin:20,
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    margin: 20,
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  },
  container6: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'space-around',
    width:'60%',
    margin: 20,
  },
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    TableHead: {
        textAlign: 'center',
        fontSize: 20,
        margin:8,
        fontWeight:'bold',
      },
      TableItem: {
          textAlign: 'center',
          fontSize: 16,
          margin:10,
        },
});

// export default Home;
