const images = {
  CIE: require('./axescie.png'),
  Light: require('./LIGHT.jpg'),
  Map:require('./UPSStoreMap.png'),
  SUDO:require('./SUDO.png'),
  ElecDev:require('./ElectronicDevices.jpg'),
  MakerElec:require('./makerelectronics.jpg'),
  MakerElec2:require('./makerelectronics2.jpg'),
  Laptops:require('./laptoptable.jpg'),
  Laptops2:require('./laptoptable2.jpg'),
  Phonedis:require('./phonedis.jpg'),
  Laptopdis:require('./laptopdis.jpg'),
  InsidePC:require('./insidePC.jpg'),
  Graphicscd:require('./graphicscd.jpg'),
  AMDpro:require('./AMDproc.jpg'),
  Desktop:require('./desktop.jpg'),
  Coding:require('./coding.jpg'),
  Consult:require('./consult.jpg'),
  DeviceChart:require('./deviceflowchart.jpg'),


}
export default images;
