import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button, TextInput,  Platform, Picker, Pressable } from 'react-native';
import { Formik, useFormik, Field } from 'formik';


import Header from '../components/Header';
import QForm from "./QForm";
import images from '../assets/images/index.js';

const {width, height} = Dimensions.get('window');



function pickerIOS(){
     if (Platform.OS === 'ios'){
       return(
       <View style={{flexDirection: 'column', height:88, justifyContent: 'center', borderStyle:'solid', borderColor: '#000070', borderWidth:2}}>

       <Picker
       style={{height: 100, width: 100,}}
       itemStyle= {{color:'#000070', fontWeight: 'bold', fontSize: 18, height: 100,}}
       >
       <Picker.Item label="AB" value="AB" />
       <Picker.Item label="BC" value="BC" />
       <Picker.Item label="MB" value="MB" />
       <Picker.Item label="NB" value="NB" />
       <Picker.Item label="NL" value="NL" />
       <Picker.Item label="NS" value="NS" />
       <Picker.Item label="ON" value="ON" />
       <Picker.Item label="PE" value="PE" />
       <Picker.Item label="QB" value="QB" />
       <Picker.Item label="SK" value="SK" />
       </Picker>
       </View>
     )
     }
     else {
         return(
           <View>
           <View>
           <Text>Province</Text>
           </View>
       <View style={{flexDirection: 'column', height:34, justifyContent: 'center', borderStyle:'solid', borderColor: '#000070', borderWidth:2}}>
       <Picker
       style={{height: 34, width: 100}}
       itemStyle= {{color:'#000070', fontWeight: 'bold'}}
       >
       <Picker.Item label="" value="" />
       <Picker.Item label="AB" value="AB" />
       <Picker.Item label="BC" value="BC" />
       <Picker.Item label="MB" value="MB" />
       <Picker.Item label="NB" value="NB" />
       <Picker.Item label="NL" value="NL" />
       <Picker.Item label="NS" value="NS" />
       <Picker.Item label="ON" value="ON" />
       <Picker.Item label="PE" value="PE" />
       <Picker.Item label="QB" value="QB" />
       <Picker.Item label="SK" value="SK" />
       </Picker>
       </View>
       </View>
     )
     }
   };



export default class Contact extends React.Component {


  render() {
  return (
    <View style={styles.outer}>
    <ScrollView style={{width:'100%'}}>
      <View style={styles.container2}>
      <View style={styles.container3}>
      <Image
        style={{resizeMode: 'contain', width:'100%', height:0.5*height, marginLeft:10}}
        source={images['Map']}/>
      </View>
    </View>
  <View style={styles.container2}>
  <View style={styles.container3}>
  <View style={{flexDirection:'row'}}>
  <View style={{flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
  <Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 24}}>
  Tyson Cogger
  {"\n"}
  <Text style={{textAlignVertical:'center', fontWeight:'bold', fontSize:17}}>
  Senior Technician
  <Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
    {"\n"}
  2525 St. Clair Avenue W
  {"\n"}
  Suite 894
  {"\n"}
  Toronto, ON
  {"\n"}
  M6N 4Z5
  {"\n"}
  416.268.8409
  </Text>
  </Text>
  </Text>
  </View>
  <View style={{flexDirection:'column', justifyContent:'flex-end', alignItems:'right', paddingLeft:80 }}>
  <Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 24}}>
  C Rogers
  {"\n"}
  <Text style={{textAlignVertical:'center', fontWeight:'bold', fontSize:17}}>
  Software Consultant
  <Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
    {"\n"}
  2525 St. Clair Avenue W
  {"\n"}
  Suite 894
  {"\n"}
  Toronto, ON
  {"\n"}
  M6N 4Z5
  {"\n"}
  416.268.8409
  </Text>
  </Text>
  </Text>
  </View>
  </View>
  </View>
  </View>
  <View style={styles.container2}>
  </View>
  <View style={styles.container4}>
  <View style={styles.container5}>
  </View>
  </View>
      </ScrollView>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,
    width:'100%',

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'60%',
    margin: 20,
  },
  container4: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    flexDirection:'column',
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    padding:20,

  },
title: {
  fontSize:24,
  fontWeight:'bold',
width: '100%',
alignItems: 'center',
justifyContent: 'center',
textAlign:'center',
marginBottom:20,
},
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    input: {
    margin: 15,
    borderColor: 'black',
    borderWidth: 1,
    lineHeight:25,
    width: '35%',
    alignItems: 'flex-end',
  },
  input2: {
  margin: 15,
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:25,
  width: '90%',
},
input3: {
margin: 15,
borderColor: 'black',
borderWidth: 1,
lineHeight:25,
width: '10%',
},
BtnCtn: {
    elevation: 8,
    borderRadius: 10,
    borderWidth:2,
    borderColor:'#ffff',
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor:'#000070',
    width:200,
    height:50,
  },
BtnText: {
    borderColor:'#000070',
    fontSize: 18,
    fontWeight: "normal",
    alignSelf: "center",
    textTransform: "uppercase",
    color:'#ffff'
  }
});
