import React, { useState } from "react";
import {Dimensions, Alert, Modal, StyleSheet, Text, Pressable, View, Image, ScrollView, TextInput, Platform, Picker } from 'react-native';
import { Formik, useFormik, Field } from 'formik';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Yup from 'yup';
import { Button, CheckBox } from 'react-native-elements';

import images from '../assets/images/index.js';

const {width, height} = Dimensions.get('window');


function SubForm (values, setModalVisible2, setModalVisible3){
       let data = values;


       var xhr = new XMLHttpRequest();
       xhr.open('POST', "https://90fvgyect0.execute-api.us-east-2.amazonaws.com/Prod/submitForm", true);
       xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
       xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

       xhr.send(JSON.stringify(data));

       xhr.onloadend = response => {
         if (response.target.status === 200) {
           setModalVisible2(true)
         } else {
           setModalVisible3(true)
         }
       };
   };


  const FormMobNav = () => {
    const [modalVisible2, setModalVisible2] = useState(false);
    const [modalVisible3, setModalVisible3] = useState(false);
  return (
    <View style={styles.outer}>
    <View style={styles.centeredView}>
          <View style={styles.modalView}>
            {FormScreen(setModalVisible2, setModalVisible3)}
        </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible2}
      >
        <View style={styles.centeredView2}>

          <View style={styles.modalView}>
          <View style={{width:'100%', alignItems:'flex-end', padding:10}}>
          <Pressable
            onPress={() => setModalVisible2(false)}
          >
        <MaterialCommunityIcons name="close" color='#000070' size={28} />
          </Pressable>
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:10}}>
          <MaterialCommunityIcons name="check-circle" color='green' size={50} />
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:15}}>
            <Text style={styles.textStyle2}>
            Quote Request Received!
            {"\n"}{"\n"}
            We will respond to your quote request within the next 24-48 hours.
            {"\n"}{"\n"}
            Thank you!
            </Text>
          </View>
          </View>

        </View>

      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible3}
      >
        <View style={styles.centeredView}>

          <View style={styles.modalView}>
          <View style={{width:'100%', alignItems:'flex-end'}}>
          <Pressable
            onPress={() => setModalVisible3(false)}
          >
        <MaterialCommunityIcons name="close" color='#000070' size={26} />
          </Pressable>
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:10}}>
          <MaterialCommunityIcons name="alert-circle" color='red' size={50} />
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:15}}>
            <Text style={styles.textStyle2}>
            Uh-Oh!
            {"\n"}{"\n"}
            Unfortunately there was an error submitting your Quote Request.
            {"\n"}{"\n"}
            Please try agian.
            </Text>
          </View>
          </View>

        </View>

      </Modal>
    </View>
    </View>
  );
};

const FormCheckSchema = Yup.object().shape({
  name: Yup
  .string()
  .required('*Required'),
  email: Yup
  .string()
  .email('Invalid email')
  .required('*Required'),
  compname: Yup
  .string()
  .required('*Required'),
  phoneNumber: Yup
  .string()
  .matches(/(\d){10}\b/, 'Enter a valid phone number')
  .required('*Required'),
});


const FormScreen = (setModalVisible2, setModalVisible3) => {
  return (
    <Formik
      validationSchema= {FormCheckSchema}
      initialValues={{name:'', email:'', phone:'', compname:'', message:''}}
      onSubmit={(values) => {SubForm(values, setModalVisible2, setModalVisible3) }}
    >
    {({handleChange, handleSubmit, handleBlur, values, errors, touched, isValid, setFieldValue}) => (
<View style={{width:'100%'}}>
<ScrollView style={{width:'100%'}}>
      <View style={{flexDirection:'row', width:'100%'}}>
      <Text style={styles.title}>Quote Request</Text>
      </View>
  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%'}}>
    <View style={styles.masname}>
        <Text style={{textAlign:'right'}}>Name:</Text>
    </View>
    <View style={styles.masinput}>
    <TextInput
      style={styles.input}
      value={values.name}
      onChangeText={handleChange('name')}
    />
    {errors.name &&
   <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.name}</Text>
  }
    </View>
    </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Company Name:</Text>
  </View>
<View style={styles.masinput}>
    <TextInput
  style={styles.input}
  value={values.compname}
  onChangeText={handleChange('compname')}
  />
  {errors.compname &&
 <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.compname}</Text>
}
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Email:</Text>
  </View>
  <View style={styles.masinput}>
      <TextInput
      name="email"
        style={styles.input}
        value={values.email}
        onChangeText={handleChange('email')}
      />
      {errors.email &&
     <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.email}</Text>
    }
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Phone:</Text>
  </View>
  <View style={styles.masinput}>
      <TextInput
      name="phoneNumber"
      keyboardType='numeric'
        style={styles.input4}
        value={values.phoneNumber}
        onChangeText={handleChange('phoneNumber')}
      />
      {errors.phoneNumber &&
     <Text style={{ fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.phoneNumber}</Text>
    }
  </View>
  </View>

  <View style={{flexDirection:'column', alignItems:'flex-start', paddingTop:20, paddingLeft:15}} >
  <Text style={{lineHeight:30, fontWeight:'bold', fontSize:18}}>Requesting A Quote For:</Text>
  <Text style={{lineHeight:20, fontStyle:'italic', fontSize:14}}>Select All that Apply</Text>
  </View>
  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', paddingTop:5 }}>
  <CheckBox
  checkedColor='#000070'
  containerStyle={styles.checkBoxContainer}
  checkedIcon='check-circle'
  uncheckedIcon='circle-o'
  title='Spectrophotometer'
  textStyle={{fontSize:16}}
  checked={values.spectrocheck}
  onPress={() => setFieldValue('spectrocheck', !values.spectrocheck)}
/>
  </View>
  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%' }}>
  <CheckBox
  checkedColor='#000070'
  containerStyle={styles.checkBoxContainer}
  checkedIcon='check-circle'
  uncheckedIcon='circle-o'
  title='Colour/Light Booth'
  textStyle={{fontSize:16}}
  checked={values.boothcheck}
  onPress={() => setFieldValue('boothcheck', !values.boothcheck)}
/>
  </View>
  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%',}}>
  <CheckBox
  checkedColor='#000070'
  containerStyle={styles.checkBoxContainer}
  checkedIcon='check-circle'
  uncheckedIcon='circle-o'
  title='Gloss Meter'
  textStyle={{fontSize:16}}
  checked={values.glosscheck}
  onPress={() => setFieldValue('glosscheck', !values.glosscheck)}
/>
  </View>
  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', paddingBottom:15 }}>
  <CheckBox
  checkedColor='#000070'
  containerStyle={styles.checkBoxContainer}
  checkedIcon='check-circle'
  uncheckedIcon='circle-o'
  title='UV Lamps'
  textStyle={{fontSize:16}}
  checked={values.uvcheck}
  onPress={() => setFieldValue('uvcheck', !values.uvcheck)}
/>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', padding:10 }}>
  <Text style={{textAlign:'right', fontWeight:'bold', fontSize:16}}>Instrument 1</Text>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Manufacturer:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    style={styles.input}
    value={values.Mfg}
    onChangeText={handleChange('Mfg')}
  />
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Model or P/N:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    style={styles.input}
    value={values.modelPN}
    onChangeText={handleChange('modelPN')}
  />
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Quantity:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    keyboardType='numeric'
    style={styles.input3}
    value={values.quantity}
    onChangeText={handleChange('quantity')}
  />
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', padding:10 }}>
  <Text style={{textAlign:'right', fontWeight:'bold', fontSize:16}}>Instrument 2</Text>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Manufacturer:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    style={styles.input}
    value={values.Mfg2}
    onChangeText={handleChange('Mfg2')}
  />
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Model or P/N:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    style={styles.input}
    value={values.modelPN2}
    onChangeText={handleChange('modelPN2')}
  />
  </View>
  </View>

  <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>
  <View style={styles.masname}>
      <Text style={{textAlign:'right'}}>Quantity:</Text>
  </View>
  <View style={styles.masinput}>
  <TextInput
    keyboardType='numeric'
    style={styles.input3}
    value={values.quantity2}
    onChangeText={handleChange('quantity2')}
  />
  </View>
  </View>


          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', paddingTop:20, marginBottom:30}} >
          <View style={styles.masname2}>
              <Text style={{textAlign:'right'}}>Message:</Text>
          </View>
          <View style={styles.masinput2}>
          <TextInput
            multiline
            numberOfLines={6}
            placeholder='List any other instruments here or other important info or questions'
            style={styles.input2}
            value={values.message}
            onChangeText={handleChange('message')}
          />
          </View>
          </View>

        <View style={{flexDirection:'row', justifyContent:'center', width:'100%', paddingBottom:10}} >
        <Pressable onPress={handleSubmit} style={styles.BtnCtn} >
        <Text style={styles.BtnText}>
        Send
        </Text>
        </Pressable>
      </View>
      </ScrollView>
      </View>
    ) }
  </Formik>
)
}

export default FormMobNav


const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width:'60%',
    margin: 20,
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  },
  title: {
    fontSize:24,
    fontWeight:'bold',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign:'center',
  marginBottom:30,
  marginTop:20,
  textDecorationLine:'underline'
  },
    space: {
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100%',
      },
  masinput:{
    width: '65%',
    padding:10,
  },
  masname:{
    width: '35%',
    padding:10,
  },
  masinput2:{
    width: '75%',
    padding:10,
  },
  masname2:{
      width: '25%',
    padding:10,
  },
      input: {
      borderColor: 'black',
      borderWidth: 1,
      lineHeight:35,
      width: '100%',
      paddingLeft:5,
      borderRadius:10,
    },
    input2: {
    borderColor: 'black',
    borderWidth: 1,
    lineHeight:25,
    justifyContent:'flex-start',
    alignItems:'flex-start',
    textAlignVertical:'top',
    width: '100%',
    padding:5,
    borderRadius:10,

  },
  input3: {
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:25,
  width: '25%',
  paddingLeft:5,
  borderRadius:10,
  },
  input4: {
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:25,
  width: '60%',
  paddingLeft:5,
  borderRadius:10,
  },
  BtnCtn: {
      elevation: 0,
      borderRadius: 10,
      borderWidth:2,
      borderColor:'#ffff',
      paddingVertical: 8,
      paddingHorizontal: 8,
      backgroundColor:'#000070',
      width:200,
      height:50,
      marginBottom:10,
    },
  BtnText: {
      borderColor:'#000070',
      fontSize: 18,
      fontWeight: "normal",
      alignSelf: "center",
      textTransform: "uppercase",
      color:'#ffff'
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
      marginBottom:30,
    },
    centeredView2: {
      height:'100%',
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
      marginBottom:30,
      backgroundColor: 'black',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalView: {
      width:'95%',
      backgroundColor: "white",
      borderRadius: 20,
      borderColor:'#000070',
      borderWidth:2,
      padding:5,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      paddingBottom: 50,
    },
    button: {
      borderRadius: 20,
      padding: 20,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#000070",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontSize:18,

    },
    textStyle2: {
      color: "#000070",
      fontWeight: "bold",
      fontSize:18,
      textAlign:'center',
      lineHeight:30,
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    checkBoxContainer: {
      backgroundColor: "#ffff",
      borderWidth:0,
      justifyContent:'center',
      alignItems:'center',

    },
  });
