import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, Platform} from 'react-native';
import images from './assets/images/index.js';
import MainStackNavigator from './MainStackNavigator'

const {width, height} = Dimensions.get('window');
const isWeb = Platform.OS === 'web';



export default function App() {
    if (width > 500){
  return (
    <View style={styles.outer}>
      < MainStackNavigator />
    </View>

  )
}
else {
  return (
    <View style={styles.outer}>
    <View style={styles.container}>
    <Image
      style={{justifyContent: 'center', alignItems:'center', resizeMode: 'contain', height:height*0.1, width:height*0.1}}
      source={images['SUDO']}/>
      <View style={{flexDirection:"column", margin:16, marginLeft:36 }}>
      <Text style={{textAlign:'center', fontWeight:'bold', color:'#fff', fontSize:height*0.1*0.28}}>SUDO</Text>
      <Text style={{textAlign:'center', fontWeight:'bold', color:'#fff', fontSize:height*0.1*0.14}}>Hardware & Software Consultants</Text>
      <Text style={{textAlign:'center', fontWeight:'bold', color:'#fff', fontSize:height*0.1*0.14}}>{"\n"}{"\n"}Mobile Site Coming Soon</Text>
    </View>
      </View>
    </View>
  )
}
}


const styles = StyleSheet.create({

  outer: {
    flex: 1,
  },
  container: {
    height: height*3/10,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    width:'100%',
  },
  container3: {
    flex: 1,
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    width:'80%',
    height:'100%',
  },
});
