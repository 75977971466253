import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button, Platform } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');
import QForm from "./QForm";
import QForm2 from "./FormMob";

import { NavigationContainer, CommonActions } from '@react-navigation/native';

const isWeb = Platform.OS === 'web';


export default class Home extends React.Component {


  render() {
  return (
    <View style={styles.outer}>
      <ScrollView style={{width:'100%'}}>
      <View style={styles.container}>
      <table style={{width:'100%', height:'200px'}}>
      <tbody style={{width:'100%', height:'200px'}}>
       <tr style={{width:'100%', height:'200px'}}>
         <td style={{width:'25%'}}>
         <Image
          style={{justifyContent: 'flex-start', resizeMode:'cover', width:'100%', height:'100%'}}
          source={images['Phonedis']}/>
                 </td>
         <td style={{width:'25%'}}>
         <Image
          style={{justifyContent: 'flex-start', resizeMode:'cover', width:'100%', height:'100%'}}
          source={images['Laptopdis']}/>
          </td>
         <td style={{width:'25%'}}>
         <Image
           style={{resizeMode: 'cover', width:'100%', height:'100%', margin:'auto'}}
           source={images['Graphicscd']}/>
          </td>
          <td style={{width:'25%'}}>
          <Image
            style={{resizeMode: 'cover', width:'100%', height:'100%',margin:'auto'}}
            source={images['AMDpro']}/>
          </td>
       </tr>
      </tbody>
      </table>
      </View>
      <View style={styles.container2}>
      <View style={styles.container3}>
  <Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
  {"\n"}{"\n"}
WHO WE ARE
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
We are your Canadian hardware and software experts for all hardware and software issues that may arise. Be relieved in knowing that we will work with you through every step of the way towards resolving the issue or completing your vision.
{"\n"}{"\n"}
We have been performing hardware and sofware consulting services
for over 20 years and have no doubt we will find a solution to the
problem you may be experiencing or the idea that you want to create.
{"\n"}{"\n"}
</Text>
</Text>
<Image
  style={{resizeMode: 'contain', width:'50%', height:'75%', marginLeft:10}}
  source={images['InsidePC']}/>
    </View>
    </View>

    <View style={styles.container5}>
    <View style={styles.container4}>
<Text style={{width:'50%', margin:5, textAlign: 'center', fontSize:24, fontWeight:'750'}}>
Our Services {"\n"}{"\n"}
</Text>

</View>
<View style={{flexDirection:'row', width:'50%', margin:10}}>
<View style={{width:'33%',flex:1}}>
<Text style={styles.TableHead}>
Hardware
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Custom PC Builds</Text>
<Text style={styles.TableItem}>{'\u2022'}  PC Repair</Text>
<Text style={styles.TableItem}>{'\u2022'}  Troubleshooting</Text>
<Text style={styles.TableItem}>{'\u2022'}  Connectivity Issues</Text>
<Text style={styles.TableItem}>{'\u2022'}  Raspberry Pi Builds & Coding</Text>
</View>
<View style={{width:'34%',flex:1}}>
<Text style={styles.TableHead}>
Software
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Installation</Text>
<Text style={styles.TableItem}>{'\u2022'}  Driver Issues</Text>
<Text style={styles.TableItem}>{'\u2022'}  Troubleshooting</Text>
</View>
<View style={{width:'33%',flex:1}}>
<Text style={styles.TableHead}>
Other
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Website Design & Building</Text>
<Text style={styles.TableItem}>{'\u2022'}  Graphic Design</Text>
<Text style={styles.TableItem}>{'\u2022'}  Card Printing Services</Text>
<Text style={styles.TableItem}>{'\u2022'}  Custom Javascript/Python Scripts</Text>
<Text style={styles.TableItem}>{'\u2022'}  Consulting</Text>
</View>
</View>
  </View>

  <View style={styles.container7}>
  <Image
    style={{justifyContent: 'flex-start', resizeMode: 'cover', width:'100%', height:'100%'}}
    source={images['MakerElec2']}/>
  </View>
      </ScrollView>
      </View>
    )
}





}




const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height: '100%',
    backgroundColor: '#ffffff',
    maxWidth: width,
    width:'100%',

  },
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'100%',

  },
  container7: {
    height: height*0.40,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'100%',

  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',

  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    margin: 20,
  },
  container6: {
    flexDirection:'row',
    backgroundColor:Platform.OS === 'web' ? '#D4F1F4' : '#ffff',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width:'60%',
    margin: 20,
  },
space: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  TableHead: {
      textAlign: 'center',
      fontSize: 20,
      margin:8,
      fontWeight:'500',
    },
    TableItem: {
        textAlign: 'center',
        fontSize: 16,
        margin:4,
      },
  container4: {
    flex:1,
    flexDirection:'column',
    backgroundColor: '#D4F1F4',
    alignItems: 'center',
    justifyContent: 'center',
    width:'70%',

  },
  container5: {
    backgroundColor: '#D4F1F4',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  paddingBottom: 60,

  },
});
