import React from 'react';
import { StyleSheet, Text, View, Dimensions, Image, Button, Platform } from 'react-native';
import images from '../assets/images/index.js';

const {width, height} = Dimensions.get('window');

const isWeb = Platform.OS === 'web';

function Footer () {
        return (
          <View style={{width:'100%', borderTopWidth:8, borderColor:'#D4F1F4', backgroundColor: '#000', height:height*0.15,justifyContent: 'center',}} >
            <View  style={styles.header}>
            <Image
              style={{justifyContent: 'center', resizeMode: 'contain', height:height*0.06, width:height*0.06}}
              source={images['SUDO']}/>
              <View style={{flexDirection:"column", margin:16}}>
              <Text style={{textAlign:'center', fontSize:14, color:'#fff'}}>SUDO. 2023 All Rights Reserved </Text>
            </View>
            </View>

            </View>
)
}


const styles = StyleSheet.create({
        header: {
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            width:'100%',
            height:height*0.10,
        },
});
export default Footer;
