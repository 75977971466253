import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');
import FormM from "./FormMob";

export default class Services extends React.Component {


  render() {
  return (
    <View style={styles.outer}>
    <ScrollView style={{width:'100%'}}>
      <View style={styles.container2}>
      <View style={styles.container3}>

  <Text style={{margin:20, textAlign: 'center', textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
Calibration & Repair Services

</Text>
<Text style={{margin:20, textAlign: 'left', textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
We have been performing calibration and repair services on X-Rite, Hunterlab, and DataColor spectrophotometers and light booths within Canada for more than 40 years, and have gone through extensive training at X-Rite facilities on their latest spectrophotometers and colour booths. We currently perform calibration services on-site & in-lab throughout Canada at a variety of different industries including:
{"\n"}{"\n"}
<View style={{flexDirection:'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
<Text style={styles.TableItem}>{'\u2022'} Automotive- Tier 1 & Tier 2 manufacturers</Text>
<Text style={styles.TableItem}>{'\u2022'} Plastics Manufacturers</Text>
<Text style={styles.TableItem}>{'\u2022'} Textile Manufacturers</Text>
<Text style={styles.TableItem}>{'\u2022'} Furniture Manufacturers</Text>
</View>
{"\n"}{"\n"}
Be confident in knowing that our current radiometer comes with ISO-17025 certification and is calibrated every year for auditing purposes and peace of mind.

</Text>
<Image
  style={{resizeMode: 'contain', width:'50%', height:0.4*height}}
  source={images['CarSeat']}/>
    </View>
    </View>
    <View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:120, }}>
    <View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
    </View>
    <View style={styles.container2}>
    <View style={styles.container3}>
<Text style={{margin:20, textAlign: 'left', fontSize:22, fontWeight:'bold',}}>
Calibration Procedure
{"\n"}{"\n"}
<Text style={{fontWeight:'normal', fontSize:16}}>
Under normal circumstances we can usually perform the calibration services on-site within a few hours. When calibrating a colour/light booth we first perform a calibration test to get a benchmark for the light output and colour temperature of each light source, we then perform the cleaning of each bulb and all filters and reflectors, as well as replacing a bulb if need be.
{"\n"}{"\n"}
We then proceed with the final calibration test using our
ISO- 17025 certified radiometer to record the current values of the colour temperature and light output, in lumens, of each light source.
{"\n"}{"\n"}
If there is a large enough variance, depending on the tolerance that has been set, we will perform an adjustment on the booth itself until the unit is within the tolerance range.
{"\n"}
</Text>
</Text>
<Image
  style={{resizeMode: 'contain', width:'60%', height:0.3*height, marginLeft:10,}}
  source={images['HeadRest']}/>
</View>
</View>
<View style={{width:'100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#D4F1F4', padding:20}}>
<View style={{flexDirection:'row', alignItems: 'flex-start', justifyContent: 'space-evenly', width:'90%'}}>
<View style={{width:'50%', }}>
<Text style={styles.TableHead}>
Hunterlab
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Labscan XE</Text>
<Text style={styles.TableItem}>{'\u2022'}  D25 DP9000</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorQuest</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorFlex EZ</Text>
</View>
<View style={{width:'50%',flex:1}}>
<Text style={styles.TableHead}>
X-Rite
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci5/Ci7</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 7000/CE 7000A</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 3100</Text>
<Text style={styles.TableItem}>{'\u2022'}  SP-60 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  964 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci6 Series {"\n"}{"\n"}</Text>
</View>
</View>
<View style={{flexDirection:'row', alignItems: 'flex-start', justifyContent: 'space-evenly', width:'90%'}}>
<View style={{width:'50%',flex:1}}>
<Text style={styles.TableHead}>
Gretag-Macbeth
</Text>
<Text style={styles.TableItem}>{'\u2022'} SPL I, II, III, QC</Text>
<Text style={styles.TableItem}>{'\u2022'}  SPL Judge, Judge QC</Text>
</View>
<View style={{width:'50%',flex:1}}>
<Text style={styles.TableHead}>
Datacolor
</Text>
<Text style={styles.TableItem}>{'\u2022'}  400, 500, 600, 800</Text>
<Text style={styles.TableItem}>{'\u2022'}  110, 200</Text>
<Text style={styles.TableItem}>{'\u2022'}  TruVue Light Booth</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorMatcher Light Booth</Text>
</View>
</View>
</View>
<View style={styles.container2}>
<View style={styles.container6}>
<Text style={{width:'100%', textAlignVertical:'center', fontWeight:'bold', fontSize: 22, }}>
Request A Quote
{"\n"}{"\n"}
<Text style={{width:'100%', fontSize: 18, fontWeight:'normal', textAlign: 'left', }}>
We currently perform calibrations services throughout Canada, with a strong focus in Ontario and Quebec. However we do perform calibration and repair services once a year throughout BC, Alberta, and Manitoba as well as PEI, New Brunswick, and Nova Scotia.
{"\n"}{"\n"}
If you are need of your spectrophotometer or colour booth being calibrated or repaired let us know and we may be able to help you out.
</Text>
</Text>
<View style={styles.container7}>
<View style={styles.container8}>
<FormM/>
</View>
</View>
<Image
style={{resizeMode: 'contain', width:'70%', height:width*0.6*0.7*1.12, marginBottom:50}}
source={images['CIE']}/>
</View>
</View>


<View style={{backgroundColor:'#D4F1F4', justifyContent: 'center', alignItems: 'center', }}>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:120, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
<Text style={{width:'50%', textAlign: 'center', fontSize:30, fontWeight:'bold', }}>
UV SERVICES
</Text>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:120, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
</View>

<View style={styles.container2}>
<View style={styles.container6}>

<Text style={{width:'100%', textAlign: 'left', fontSize:24, fontWeight:'bold', marginTop:20}}>
Ultraviolet Maintenance & Repair
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
Before venturing into the calibration of spectrophotometers and colour/light booths we originally got started in the maintenance and repair of ultraviolet curing machines and have built custom designed uv curing machines. We still maintain and repair uv curing machines, including being able to source any ultraviolet light bulb for your UV unit.
{"\n"}{"\n"}
Many of our customers that operate UV curing units are hardwood flooring manufacturers, since they are in need of an efficient means to cure the coating that is applied on most hardwood floors, to protect from scratches as well as having better durability from regular wear and tear.
{"\n"}{"\n"}
</Text>
</Text>
<Image
style={{resizeMode: 'contain', width:'90%', height:0.3*height}}
source={images['Floor']}/>
</View>
</View>

<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:100, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
<View style={styles.container2}>
<View style={styles.container3}>
<Text style={{width:'90%', textAlign: 'center', fontSize:22, fontWeight:'bold',}}>
Other Industries
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
There are also many other uses of ultraviolet bulbs either than for the curing of hardwood floors, including for air and water sterilization. These units are usually in operation at the municipal level for the treatment of water, as well as in hospitals for the sterilization of air.
{"\n"}{"\n"}
Finally, you can also find smaller and less intense uv units at nail salons for the curing of nail polish. Many nail salons currently use UV LED units as they are becoming cheaper to use over the long term, due the lamp life of leds, which are also starting to gain traction in industrial and commercial applications, such as wide format printers and for multi-use ultraviolet curing machines.{"\n"}{"\n"}

</Text>
</Text>
</View>
</View>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:100, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
<View style={styles.container2}>
<View style={styles.container3}>
<Image
style={{resizeMode: 'contain', width:'80%', height:0.4*height}}
source={images['Lamp']}/>
<Text style={{width:'80%', textAlign: 'center', fontSize:22, fontWeight:'bold',}}>
Need a UV Bulb?
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
We are able to source most ultraviolet light bulbs from multiple different setups, including machines from Mark Andy, Fusion UV, and American Ultraviolet. As well, we may be able to find a close enough match of your current bulb for a cheaper price, based on the length and end connector of your specific bulb.
{"\n"}{"\n"}
Considering we operate at a large scale based on the # of bulbs we currently purchase and supply to our customers we just may be able to find you a cheaper price per bulb.
{"\n"}{"\n"}
</Text>
</Text>

</View>
</View>
      </ScrollView>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    alignItems: 'center',
    alignContent:'center',
    justifyContent: 'center',
    width:'100%',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'90%',
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  },
  container6: {
    flexDirection:'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width:'70%',
    margin: 20,
  },
  container7: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container8: {
    flexDirection:'column',
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    padding:0,
  },
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    TableHead: {
        textAlign: 'center',
        fontSize: 20,
        margin:8,
        fontWeight:'bold',
      },
      TableItem: {
          textAlign: 'center',
          fontSize: 16,
          margin:10,
        },
});

// export default Home;
