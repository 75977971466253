import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');


export default class About extends React.Component {

  render() {
  return (
    <View style={styles.outer}>
    <ScrollView style={{width:'100%'}}>
      <View style={styles.container2}>
      <View style={styles.container3}>
      <Image
        style={{resizeMode: 'contain', width:'45%', height:'75%', marginLeft:10}}
        source={images['ElecDev']}/>
  <Text style={{paddingLeft:100, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
{"\n"}
Canadian Calibration Experts
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16, lineHeight:20}}>
We are your Canadian calibration experts for all equipment related to the colour reflectance of your product or material. Be confident in knowing that your quality control procedures meets the same requirements as multi-billion dollar businesses.
{"\n"}{"\n"}
We have been performing on-site calibration services on X-rite and Hunterlab meters within Canada for more than 40 years, and have gone through extensive training at X-rite facilities on the latest spectrophotometers and colour booths including all X-rite/Gretag- Macbeth, Hunterlab, and Datacolor equipment.
{"\n"}{"\n"}
<View style={{flexDirection:'row', width:'100%', justifyContent: 'space-between'}}>


</View>
</Text>
</Text>
</View>
  </View>

    <View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:100, }}>
    <View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
    </View>
      </ScrollView>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    margin: 20,
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,

  },
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    TableHead: {
        textAlign: 'left',
        fontSize: 20,
        margin:8,
      },
      TableItem: {
          textAlign: 'left',
          fontSize: 16,
          margin:4,
        },
});

// export default Home;
