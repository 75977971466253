import React from 'react';
import { StyleSheet, Text, View, Dimensions, Image, Button, Platform } from 'react-native';
import images from '../assets/images/index.js';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const {width, height} = Dimensions.get('window');

const isWeb = Platform.OS === 'web';

function Header () {
        return (
          <View style={{width:'100%'}}>
          <View style={{flexDirection: "row", width:'100%', backgroundColor: '#000', height:height*0.15,justifyContent: 'center',}} >

          <View style={{width:'25%', height:'100%', justifyContent: 'center', alignItems:'center' }}/>
            <View  style={styles.header}>
            <Image
              style={{justifyContent: 'center',  resizeMode: 'contain', height:height*0.1, width:height*0.1}}
              source={images['SUDO']}/>
              <View style={{flexDirection:"column", margin:16, marginLeft:36 }}>
              <Text style={{textAlign:'center', fontWeight:'bold', color:'#fff', fontSize:height*0.1*0.28}}>SUDO</Text>
              <Text style={{textAlign:'center', fontWeight:'bold', color:'#fff', fontSize:height*0.1*0.14}}>Hardware & Software Consultants</Text>
            </View>
            </View>
            <View style={{flexDirection:"column", width:'25%', alignItems: 'flex-start', justifyContent:'center' }}>
            <View style={{flexDirection:"row", width:'100%', alignItems: 'flex-start'}}>
            <MaterialCommunityIcons name="phone" color='#fff' size={26} style={{marginLeft:10}} />
            <Text style={{justifyContent:'center', alignItems:'center', color:'#fff', textAlign:'center', fontSize:height*0.1*0.20, fontWeight:'bold', marginLeft:10 }}>416.268.8409</Text>
            </View>
            <Text style={{justifyContent:'center', alignItems:'center', color:'#fff', textAlign:'center', fontSize:height*0.1*0.15, fontWeight:'bold', marginLeft:10 }}>support@superuserdo.com</Text>
  </View>
            </View>
            </View>
)
}


const styles = StyleSheet.create({
        header: {
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            width:'50%',
            height:height*0.10,
            marginTop:height*0.025,
        },
});
export default Header;
