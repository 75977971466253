import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');


export default class About extends React.Component {

  render() {
  return (
    <View style={styles.outer}>
    <ScrollView style={{width:'100%'}}>
      <View style={styles.container2}>
      <View style={styles.container3}>
      <Image
        style={{resizeMode: 'contain', width:'80%', height:0.3*height, marginLeft:10}}
        source={images['Light']}/>
  <Text style={{textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
{"\n"}
Canadian Calibration Experts
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16, lineHeight:20}}>
We are your Canadian calibration experts for all equipment related to the colour reflectance of your product or material. Be confident in knowing that your quality control procedures meets the same requirements as multi-billion dollar businesses.
{"\n"}{"\n"}
We have been performing on-site calibration services on X-rite and Hunterlab meters within Canada for more than 40 years, and have gone through extensive training at X-rite facilities on the latest spectrophotometers and colour booths including all X-rite/Gretag- Macbeth, Hunterlab, and Datacolor equipment.
{"\n"}{"\n"}
</Text>
</Text>
<View style={{flexDirection:'row', width:'100%', justifyContent: 'space-between'}}>
<View style={{flexDirection:'column', width:'33%'}}>
<Text style={styles.TableItem}>{'\u2022'}  Labscan XE</Text>
<Text style={styles.TableItem}>{'\u2022'}  D25 DP9000</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorQuest</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorFlex EZ{"\n"}{"\n"}</Text>
</View>
<View style={{flexDirection:'column',width:'33%'}}>
<Text style={styles.TableItem}>{'\u2022'}  SPL I/II/III/QC</Text>
<Text style={styles.TableItem}>{'\u2022'}  SPL Jr.</Text>
<Text style={styles.TableItem}>{'\u2022'}  Judge I/II</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci5/Ci7</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 7000/CE 7000A</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 3100</Text>
<Text style={styles.TableItem}>{'\u2022'}  SP-60 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  964 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci6 Series {"\n"}{"\n"}</Text>
</View>
<View style={{flexDirection:'column',width:'33%'}}>
<Text style={styles.TableItem}>{'\u2022'}  400, 500, 600, 800</Text>
<Text style={styles.TableItem}>{'\u2022'}  110, 200</Text>
</View>
</View>
</View>
  </View>

    <View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:100, }}>
    <View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
    </View>
<View style={styles.container2}>
<View style={styles.container3}>
<Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
Industries We Serve
<Text style={{width:'50%', margin:5, textAlign: 'left', fontSize:16, fontWeight:'normal'}}>
{"\n"}{"\n"}
Some of our customers are businesses in the automotive industry that are operating as a Tier 1 or Tier 2 supplier to automotive manufacturers such as GM and Ford, which need to have every instrument part of the manufacturing and testing process calibrated every year for quality control requirements. We have been performing calibration services at these suppliers for the last 20 years using an ISO 17025 certified instrument, which is calibrated every year.
{"\n"}{"\n"}
If you are in the need of your colour booth or spectrophotometer calibrated, or have any questions please don't hesitate to give us a call or request a quote below.
{"\n"}{"\n"}
</Text>
</Text>
<Image
  style={{resizeMode: 'contain', width:'50%', height:0.3*height, marginLeft:10}}
  source={images['CarArm']}/>
</View>
</View>
<View style={{justifyContent: 'center', alignItems: 'center', width:'100%', height:100, }}>
<View style={{justifyContent: 'center', alignItems: 'center', borderBottomColor: 'black', borderBottomWidth: 2, width:'60%', }}  />
</View>
<View style={styles.container2}>
<View style={styles.container3}>
<Text style={{textAlign:'center', textAlignVertical:'center', fontWeight:'bold', fontSize: 26}}>
Memberships & Certifications
{"\n"}{"\n"}
</Text>
<Image
  style={{resizeMode: 'contain', width:'80%', height:0.3*height}}
  source={images['ISNCert']}/>
<Text style={{margin:20, textAlign:'center', textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
ISNetworld Membership
<Text style={{margin:20, textAlignVertical:'center', fontWeight:'normal', fontSize: 16}}>
{"\n"}{"\n"}
ISNetworld is an online contractor and supplier management platform of data-driven products and services that help manage risk and strengthen relationships. It helps reduce unnecessary duplication associated with traditional qualification processes.
{"\n"}{"\n"}
Simplify Your Qualification Process
{"\n"}{"\n"}
Through ISNetworld, ISN collects, reviews and stores your health, safety and procurement information, allowing you to complete the qualification process for multiple Hiring Clients at once.
{"\n"}
</Text>
</Text>
</View>
</View>
<View style={{justifyContent: 'center', alignItems:'center', width:'100%',}}>
<Image
  style={{resizeMode: 'contain', width:'70%', height:width*0.7*0.29}}
  source={images['ISNLogo']}/>
</View>
<View style={{justifyContent: 'center', alignItems:'center', width:'100%',}}>
<Text style={{margin:40, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
ISO-17025 Certification Pending
<Text style={{margin:20, textAlignVertical:'center', fontWeight:'normal', fontSize: 16}}>
{"\n"}{"\n"}
We are in the process of completing our requirements to receive ISO-17025 certification as a business from the National Research Council of Canada.
{"\n"}{"\n"}
Our ILT-350 radiometer is currently ISO-17025 certified.
{"\n"}{"\n"}{"\n"}{"\n"}
</Text>
</Text>
</View>
      </ScrollView>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'90%',
    margin: 20,
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,

  },
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    TableHead: {
        textAlign: 'left',
        fontSize: 20,
        margin:8,
      },
      TableItem: {
          textAlign: 'left',
          fontSize: 16,
          margin:4,
        },
});

// export default Home;
