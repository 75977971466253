import React, { useState, useEffect } from 'react';
import { View, Modal, Text, Platform, Dimensions, Button, TouchableOpacity, ScrollView, Pressable, StyleSheet } from 'react-native';
import { NavigationContainer,  TabNavigator, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FAB } from 'react-native-elements';


import Header from './components/Header';
import Footer from './components/Footer';
const {width, height} = Dimensions.get('window');

import Home from "./screens/Home";
import Services from "./screens/Services";
import About from "./screens/About";
import Contact from "./screens/Contact";
import FormN from "./screens/FormNav";
import QForm from "./screens/QForm";

import HomeM from "./screens/HomeMob";
import ServicesM from "./screens/ServicesMob";
import AboutM from "./screens/AboutMob";
import ContactM from "./screens/ContactMob";
import FormM from "./screens/FormMob";
import FormMNav from "./screens/FormMobNav";


const isWeb = Platform.OS === 'ios' || 'android';


function BarNav({ screenName, scnBtn }) {
  const navigation = useNavigation();

  const [btn1, setbtn1] = useState(false)
  const [btn2, setbtn2] = useState(false)
  const [btn3, setbtn3] = useState(false)
  const [btn4, setbtn4] = useState(false)
  const [btn5, setbtn5] = useState(false)

  useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
          if (screenName === 'Home'){
              setbtn1(true);
            }
            else if (screenName === 'Services'){
                setbtn2(true);
            }
            else if (screenName === 'Contact'){
                setbtn5(true);
            }
          }
  )
    return unsubscribe;
  }, [navigation]);


  return (
    <View style={{width:'100%', backgroundColor:'#c02026', alignItems: 'center',}}>
    <View style={{flexDirection:'row', width:'50%', alignItems: 'center', justifyContent: 'space-evenly', backgroundColor:'#c02026', margin:12}}>
    <Pressable
    onPress={() => {navigation.navigate('Home')}}
    style={btn1 ? styles.appButtonContainer2 : styles.appButtonContainer}>
    {({ pressed }) => (
    <Text style={[{ color: pressed ? '#FF0000' : '#ffff' }, styles.appButtonText]}>
    Home
    </Text>
    )}
    </Pressable>
    <Pressable
    onPress={() => {navigation.navigate('Services')}}
    style={btn2 ? styles.appButtonContainer2 : styles.appButtonContainer}>
    {({ pressed }) => (
    <Text style={[{ color: pressed ? '#FF0000' : '#ffff' }, styles.appButtonText]}>
    Services
    </Text>
    )}
    </Pressable>
    <Pressable
    onPress={() => {navigation.navigate('Contact');}}
    style={btn5 ? styles.appButtonContainer2 : styles.appButtonContainer}>
    {({ pressed }) => (
    <Text style={[{ color: pressed ? '#FF0000' : '#ffff' }, styles.appButtonText]}>
    Contact
    </Text>
    )}
    </Pressable>
    <FormN/>
    </View>
      </View>
  );
}


function HomeScreen({ navigation }) {
  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <BarNav screenName="Home"/>
      <Home />
      <Footer />
    </View>

  )
}


function ServiceScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <BarNav screenName="Services"/>
    <Services />
    <Footer />
  </View>

)
}

function AboutScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <BarNav screenName="About"/>
    <About/>
    <Footer />
  </View>

)
}


function ContactScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <BarNav screenName="Contact"/>
    <Contact />
    <Footer />
  </View>

)
}


function QuoteScreen() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <BarNav screenName="Quote"/>
    <FormN />
    <Footer />
  </View>
)
}



const HomeStack = createStackNavigator();


function HomeStackScreen() {
  return (
    <NavigationContainer>
    <HomeStack.Navigator
    headerMode="screen"
    screenOptions= {{
      header: Header,
      headerMode: 'screen',
      headerStyle: { backgroundColor: '#ffff', height: 80, width:width,},
      headerTintColor: '#ffff',
    }}>
      <HomeStack.Screen name="Home" component={HomeScreen} />
      <HomeStack.Screen name="Services" component={ServiceScreen}/>
      <HomeStack.Screen name="About" component={AboutScreen}/>
      <HomeStack.Screen name="Contact" component={ContactScreen}/>
      <HomeStack.Screen name="Quote" component={QuoteScreen}/>
    </HomeStack.Navigator>
    </NavigationContainer>
  );
}



const styles = StyleSheet.create({
appButtonContainer: {
    elevation: 8,
    borderRadius: 10,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  appButtonContainer2: {
      elevation: 8,
      borderRadius: 10,
      borderWidth:2,
      borderColor:'#ffff',
      paddingVertical: 8,
      paddingHorizontal: 8,

    },
  appButtonText: {
    borderColor:'#000070',
    fontSize: 18,
    fontWeight: "normal",
    alignSelf: "center",
    textTransform: "uppercase"
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 50,
    bottom: 50,
    elevation:8
  },
  centeredView: {
    width:'100%',
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
    marginBottom:50,

  },
  modalView: {
    width:'95%',
    backgroundColor: "white",
    borderRadius: 20,
    borderColor:'#000070',
    borderWidth:2,
    padding:5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    paddingBottom: 50,
  },

})

export default HomeStackScreen;
