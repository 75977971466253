import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button, Platform } from 'react-native';
import Header from '../components/Header';
import images from '../assets/images/index.js';
const {width, height} = Dimensions.get('window');
import FormM from "./FormMob";
import QForm from "./QForm";

import { NavigationContainer, CommonActions } from '@react-navigation/native';

const isWeb = Platform.OS === 'web';


export default class Home extends React.Component {




  render() {
  return (
    <View style={styles.outer}>
      <ScrollView style={{width:'100%'}}>
      <View style={styles.container}>
       <Image
        style={{justifyContent: 'flex-start', resizeMode: 'cover', width:'100%', height:'100%'}}
        source={images['Hands']}/>
      </View>
      <View style={styles.container2}>
      <View style={styles.container3}>
  <Text style={{margin:15, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
  {"\n"}
WHO WE ARE
{"\n"}{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:18}}>
We are your Canadian calibration experts for all equipment related to the colour reflectance of your product or material. Be confident in knowing that your quality control procedures meets the same requirements as multi-billion dollar businesses.
{"\n"}{"\n"}
We have been performing on-site & in-lab calibration services on
X-Rite/Gretag-Macbeth, Hunterlab, and DataColor spectrophotometers and colour/light booths within Canada for more than 40 years, and have gone through extensive training at X-Rite facilities on their latest instruments.
{"\n"}{"\n"}
</Text>
</Text>
    </View>
    </View>

    <View style={styles.container5}>
    <View style={styles.container4}>
<Text style={{width:'80%', margin:5, textAlign: 'center', fontSize:24, fontWeight:'800'}}>
Instruments Serviced {"\n"}{"\n"}
</Text>
<View style={{width:'100%', alignItems: 'center',}}></View>
<View style={{width:'100%', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
<Text style={{fontSize: 20, textAlign: 'left', margin:12, fontWeight:'600' }}>
Spectrophotometers & Colour Booths
</Text>
</View>
</View>
<View style={{flexDirection:'row', width:'90%', margin:10}}>
<View style={{width:'33%', }}>
<Text style={styles.TableHead}>
Hunterlab
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Labscan XE</Text>
<Text style={styles.TableItem}>{'\u2022'}  D25 DP9000</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorQuest</Text>
<Text style={styles.TableItem}>{'\u2022'}  ColorFlex EZ</Text>
</View>
<View style={{width:'34%',flex:1}}>
<Text style={styles.TableHead}>
X-Rite / GretagMacbeth
</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci5/Ci7</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 7000/CE 7000A</Text>
<Text style={styles.TableItem}>{'\u2022'}  CE 3100</Text>
<Text style={styles.TableItem}>{'\u2022'}  SP-60 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  964 Series</Text>
<Text style={styles.TableItem}>{'\u2022'}  Ci6 Series {"\n"}{"\n"}</Text>
<Text style={styles.TableItem}>{'\u2022'}  SPL I/II/III/QC </Text>
<Text style={styles.TableItem}>{'\u2022'}  SPL Judge I/II/QC{"\n"} </Text>
</View>
<View style={{width:'33%',flex:1}}>
<Text style={styles.TableHead}>
Datacolor
</Text>
<Text style={styles.TableItem}>{'\u2022'}  400, 500, 600, 650, 800, 850</Text>
<Text style={styles.TableItem}>{'\u2022'}  110, 200</Text>
</View>
</View>
  </View>

  <View style={styles.container}>
  <Image
    style={{justifyContent: 'flex-start', resizeMode: 'cover', width:'100%', height:'100%'}}
    source={images['ColourTubes']}/>
  </View>

  <View style={styles.container2}>
  <View style={styles.container6}>
  <Text style={{width:'90%', textAlignVertical:'center', fontWeight:'bold', fontSize: 22, textAlign:'center' }}>
  Request A Quote
{"\n"}{"\n"}
<Text style={{width:'90%', fontSize: 18, fontWeight:'normal', textAlign: 'left', }}>
 We currently perform calibrations services throughout Canada, with a strong focus in Ontario and Quebec. However we do perform calibration and repair services once a year throughout BC, Alberta, and Manitoba as well as PEI, New Brunswick, and Nova Scotia.
{"\n"}{"\n"}
If you are need of your spectrophotometer or colour booth being calibrated or repaired let us know and we may be able to help you out.
{"\n"}{"\n"}
  </Text>
</Text>
<Image
style={{resizeMode: 'contain', width:'60%', height:width*0.6}}
source={images['Locus']}/>
  </View>
  </View>
  <View style={styles.container7}>
  <View style={styles.container8}>
  <FormM/>
  </View>
  </View>
      </ScrollView>
      </View>
    )
}





}




const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    height: height*3/10,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'100%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',

  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'90%',
    margin: 20,
  },
  container6: {
    flexDirection:'column',
    backgroundColor: Platform.OS === 'web' ? '#D4F1F4' : '#ffff',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width:'90%',
    margin: 20,
  },
space: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  TableHead: {
      textAlign: 'center',
      fontSize: 20,
      margin:8,
      fontWeight:'500',
    },
    TableItem: {
        textAlign: 'center',
        fontSize: 16,
        margin:4,
      },
  container4: {
    flex:1,
    flexDirection:'column',
    backgroundColor: '#D4F1F4',
    alignItems: 'center',
    justifyContent: 'center',
    width:'90%'
    },
  container5: {
    backgroundColor: '#D4F1F4',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  },
  container7: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container8: {
    flexDirection:'column',
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    padding:0,
  },
});
