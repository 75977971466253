import React, { useState } from "react";
import {Dimensions, Modal, Alert, StyleSheet, Text, Pressable, View, Image, ScrollView, Button, TextInput, Platform, Picker } from 'react-native';
import { Formik, useFormik, Field } from 'formik';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import * as Yup from 'yup';

import images from '../assets/images/index.js';

const {width, height} = Dimensions.get('window');


function SubForm (values, setModalVisible2, setModalVisible3){

       let data = values;


       var xhr = new XMLHttpRequest();
       xhr.open('POST', "https://90fvgyect0.execute-api.us-east-2.amazonaws.com/Prod/submitForm", true);
       xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
       xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

       xhr.send(JSON.stringify(data));

       xhr.onloadend = response => {
         if (response.target.status === 200) {
           setModalVisible2(true)
         }
         else {
           setModalVisible3(true)
         }
       };
   };

const FormNav = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalVisible3, setModalVisible3] = useState(false);

  return (
    <View style={styles.centeredView}>
    <View style={styles.centeredView3}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          this.visibleModal(false);
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>

          <View style={styles.modalView}>
          <View style={{width:'100%', alignItems:'flex-end'}}>
          <Pressable
            onPress={() => setModalVisible(false) }
          >
        <MaterialCommunityIcons name="close" color='#9a0000' size={26} />
          </Pressable>
          </View>
            {FormScreen(modalVisible, setModalVisible, setModalVisible2, setModalVisible3)}
          </View>

        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible2}
      >
        <View style={styles.centeredView}>

          <View style={styles.modalView}>
          <View style={{width:'100%', alignItems:'flex-end'}}>
          <Pressable
            onPress={() => setModalVisible2(false)}
          >
        <MaterialCommunityIcons name="close" color='#9a0000' size={26} />
          </Pressable>
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:10}}>
          <MaterialCommunityIcons name="check-circle" color='green' size={50} />
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:15}}>
            <Text style={styles.textStyle2}>
            Quote Request Received!
            {"\n"}{"\n"}
            We will respond to your quote request within the next 24-48 hours.
            {"\n"}{"\n"}
            Thank you!
            </Text>
          </View>
          </View>

        </View>

      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible3}
      >
        <View style={styles.centeredView}>

          <View style={styles.modalView}>
          <View style={{width:'100%', alignItems:'flex-end'}}>
          <Pressable
            onPress={() => setModalVisible3(false)}
          >
        <MaterialCommunityIcons name="close" color='#9a0000' size={26} />
          </Pressable>
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:10}}>
          <MaterialCommunityIcons name="alert-circle" color='red' size={50} />
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', padding:15}}>
            <Text style={styles.textStyle2}>
            Uh-Oh!
            {"\n"}{"\n"}
            Unfortunately there was an error submitting your Quote Request.
            {"\n"}{"\n"}
            Please try agian.
            </Text>
          </View>
          </View>

        </View>

      </Modal>
      </View>
      <Pressable
        style={styles.appButtonContainer}
        onPress={() => setModalVisible(true)}
      >
        {({ pressed }) => (
      <Text style={[{ color: pressed ? '#FF0000' : '#ffff' }, styles.appButtonText]}>
      Quote Request
      </Text>
    )}
      </Pressable>
    </View>
  );
};


const LoginSchema = Yup.object().shape({
  name: Yup
  .string()
  .required('*Required'),
  email: Yup
  .string()
  .email('Invalid email')
  .required('*Required'),
  compname: Yup
  .string()
  .required('*Required'),
  phoneNumber: Yup
  .string()
  .matches(/(\d){10}\b/, 'Enter a valid phone number')
  .required('*Required'),
});

const FormScreen = (modalVisible, setModalVisible, setModalVisible2, setModalVisible3) => {
  return (
    <Formik
      validationSchema= {LoginSchema}
      initialValues={{name:'', email:'', phoneNumber:'', compname:'', message:''}}
      onSubmit={(values) => {setModalVisible(false); SubForm(values, setModalVisible2, setModalVisible3);  }}
    >
      {({handleChange, handleSubmit, handleBlur, values, errors, touched, isValid,}) => (
        <View style={{width:'100%'}}>
        <Text style={styles.title}>Quote Request</Text>

    <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', width:'100%', }}>

    <View style={{flexDirection:'column', width:'20%', justifyContent:'center', height:'100%'}}>
      <View style={{margin:0, height:'30%', justifyContent:'center', }}>
          <Text style={{textAlign:'right'}}>Name:</Text>
      </View>
      <View style={{margin:0, height:25, }}>
      </View>
      <View style={{marginTop:5, height:'30%', justifyContent:'center' }}>
      <Text style={{textAlign:'right'}}>Company Name:</Text>
        </View>
      <View style={{margin:0, height:25, }}>
      </View>
      </View>

      <View style={{flexDirection:'column', width:'30%', justifyContent:'center', height:'100%'}}>
      <View style={{margin:0, height:'30%', justifyContent:'center'}}>
      <TextInput
        style={styles.input}
        value={values.name}
        onChangeText={handleChange('name')}
      />
      </View>
      <View style={{marginLeft:10, height:25, width:'100%', justifyContent:'center'}}>
      {errors.name &&
     <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.name}</Text>
    }
      </View>
        <View style={{marginTop:5, height:'30%', justifyContent:'center'}}>
        <TextInput
      style={styles.input}
      value={values.compname}
      onChangeText={handleChange('compname')}
    />
    </View>
        <View style={{marginLeft:10, height:25, justifyContent:'center'}} >
        {errors.compname &&
       <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.compname}</Text>
      }
        </View>
        </View>

        <View style={{flexDirection:'column', width:'15%', justifyContent:'center', height:'100%'}}>
        <View style={{margin: 0, height:'30%', justifyContent:'center', }}>
          <Text style={{textAlign:'right'}}>Email:</Text>
          </View>
          <View style={{margin: 0, height:25, justifyContent:'center', }}>
          </View>
          <View style={{marginTop:5, height:'30%', justifyContent:'center', }}>
            <Text style={{textAlign:'right',}}>Phone:</Text>
          </View>
          <View style={{margin:0, height:25, justifyContent:'center', }}>
          </View>
          </View>


          <View style={{flexDirection:'column', width:'30%', justifyContent:'center', height:'100%'}}>
          <View style={{margin: 0, height:'30%', justifyContent:'center'}}>
        <TextInput
          style={styles.input}
          value={values.email}
          onChangeText={handleChange('email')}
        />
        </View>
        <View style={{marginLeft:10, height:25, justifyContent:'center', }}>
        {errors.email &&
       <Text style={{fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.email}</Text>
      }
        </View>
            <View style={{marginTop:5, height:'30%', width:'100%', justifyContent:'center',}}>
          <TextInput
            style={styles.input}
            value={values.phoneNumber}
            onChangeText={handleChange('phoneNumber')}
          />
          </View>
          <View style={{marginLeft:10, height:25, justifyContent:'center'}}>
          {errors.phoneNumber &&
         <Text style={{ fontSize: 12, color: 'red', fontWeight:'bold', fontStyle:'italic' }}>{errors.phoneNumber}</Text>
        }
          </View>
        </View >
        </View>


            <View style={{flexDirection:'row'}}>
            <View style={{flexDirection:'column', alignItems:'flex-start', margin:20}} >
            <Text style={{lineHeight:30, fontWeight:'bold'}}>Requesting A Quote For:</Text>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Field type="checkbox" name="checked" value="Hardware" />
            <Text style={{lineHeight:30}}>  Hardware</Text>
            </View>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Field type="checkbox" name="checked" value="Software" />
            <Text style={{lineHeight:30}}>  Software</Text>
            </View>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Field type="checkbox" name="checked" value="Consult" />
            <Text style={{lineHeight:30}}>  Consulting</Text>
            </View>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Field type="checkbox" name="checked" value="Other" />
            <Text style={{lineHeight:30}}>  Other</Text>
            </View>
          </View>
          </View>
            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginBottom:20}} >
            <Text style={{textAlign:'right', margin:15}}>Message:</Text>
            <TextInput
              multiline
              numberOfLines={4}
              style={styles.input2}
              value={values.message}
              placeholder="Please Describe your Request"
              placeholderTextColor= '#808080'
              onChangeText={handleChange('message')}
            />
            </View>
          <View style={{flexDirection:'row', justifyContent:'center'}} >
          <Pressable onPress={handleSubmit} style={styles.BtnCtn} disabled={!isValid}>
          <Text style={styles.BtnText}>
          Send
          </Text>
          </Pressable>
        </View>
        </View>
      ) }
    </Formik>
  )
}

export default FormNav


const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    maxWidth: width,

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width:'60%',
    margin: 20,
  },
  container4: {

    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  padding:20,
  },
  title: {
    fontSize:24,
    fontWeight:'bold',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign:'center',
  marginBottom:40,
  },
    space: {
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100%',
      },
      input: {
      marginLeft:10,
      borderColor: 'black',
      borderWidth: 1,
      lineHeight:25,
      alignItems: 'flex-end',
      width: '100%',
      paddingLeft:5,
      borderRadius:10,
    },
    input2: {
    borderColor: 'black',
    borderWidth: 1,
    lineHeight:25,
    width: '90%',
    paddingLeft:5,
    borderRadius:10,

  },
  input3: {
    marginLeft:10,
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:25,
  width: '50%',
  paddingLeft:5,
  borderRadius:10,
  },
  input4: {
      marginLeft:10,
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:25,
  width: '50%',
  paddingLeft:5,
  borderRadius:10,
  },
  BtnCtn: {
      elevation: 8,
      borderRadius: 10,
      borderWidth:2,
      borderColor:'#ffff',
      paddingVertical: 8,
      paddingHorizontal: 8,
      backgroundColor:'#c02026',
      width:200,
      height:50,
    },
  BtnText: {
      borderColor:'#000070',
      fontSize: 18,
      fontWeight: "normal",
      alignSelf: "center",
      textTransform: "uppercase",
      color:'#ffff'
    },
    centeredView: {
      justifyContent: "center",
      alignItems: "center",
    },

    centeredView3: {
      justifyContent: "center",
      alignItems: "center",
      width:'100%',
    },
    centeredView2: {
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
      width:'70%',
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      borderColor:"#9a0000",
      borderStyle:"solid",
      borderWidth: 3,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 20,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#000070",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontSize:18,

    },
    textStyle2: {
      color: "#000070",
      fontWeight: "bold",
      fontSize:18,
      textAlign:'center',
      lineHeight:30,
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    },
    appButtonContainer: {
        elevation: 8,
        borderRadius: 10,
        paddingVertical: 8,
        paddingHorizontal: 8,
      },
      appButtonContainer2: {
          elevation: 8,
          borderRadius: 10,
          borderWidth:2,
          borderColor:'#ffff',
          paddingVertical: 8,
          paddingHorizontal: 8,
        },
      appButtonText: {
        borderColor:'#000070',
        fontSize: 18,
        fontWeight: "normal",
        alignSelf: "center",
        textTransform: "uppercase"
      }
  });
