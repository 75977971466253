import React from 'react';
import {Dimensions, StyleSheet, Text, View, Image, ScrollView, Button, TextInput,  Platform, Picker, Pressable, CheckBox } from 'react-native';
import { Formik, useFormik, Field, } from 'formik';


import Header from '../components/Header';
import images from '../assets/images/index.js';
import FormM from "./FormMob";

const {width, height} = Dimensions.get('window');

function FormSub (values){

      // Capture the form data
       let data = values;
       console.log('Sending: ', JSON.stringify(data));

       // Create the AJAX request
       var xhr = new XMLHttpRequest();
       xhr.open('POST', "https://90fvgyect0.execute-api.us-east-2.amazonaws.com/Prod/submitForm", true);
       xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
       xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

       // Send the collected data as JSON
       xhr.send(JSON.stringify(data));

       xhr.onloadend = response => {
         if (response.target.status === 200) {
           console.log('Form submitted. Success!');
         } else {
           console.log('Error! Please try again.');
           console.error(JSON.parse(response));
         }
       };
   };

function pickerIOS(){
     if (Platform.OS === 'ios'){
       return(
       <View style={{flexDirection: 'column', height:88, justifyContent: 'center', borderStyle:'solid', borderColor: '#000070', borderWidth:2}}>

       <Picker
       style={{height: 100, width: 100,}}
       itemStyle= {{color:'#000070', fontWeight: 'bold', fontSize: 18, height: 100,}}
       >
       <Picker.Item label="AB" value="AB" />
       <Picker.Item label="BC" value="BC" />
       <Picker.Item label="MB" value="MB" />
       <Picker.Item label="NB" value="NB" />
       <Picker.Item label="NL" value="NL" />
       <Picker.Item label="NS" value="NS" />
       <Picker.Item label="ON" value="ON" />
       <Picker.Item label="PE" value="PE" />
       <Picker.Item label="QB" value="QB" />
       <Picker.Item label="SK" value="SK" />
       </Picker>
       </View>
     )
     }
     else {
         return(
           <View>
           <View>
           <Text>Province</Text>
           </View>
       <View style={{flexDirection: 'column', height:34, justifyContent: 'center', borderStyle:'solid', borderColor: '#000070', borderWidth:2}}>
       <Picker
       style={{height: 34, width: 100}}
       itemStyle= {{color:'#000070', fontWeight: 'bold'}}
       >
       <Picker.Item label="" value="" />
       <Picker.Item label="AB" value="AB" />
       <Picker.Item label="BC" value="BC" />
       <Picker.Item label="MB" value="MB" />
       <Picker.Item label="NB" value="NB" />
       <Picker.Item label="NL" value="NL" />
       <Picker.Item label="NS" value="NS" />
       <Picker.Item label="ON" value="ON" />
       <Picker.Item label="PE" value="PE" />
       <Picker.Item label="QB" value="QB" />
       <Picker.Item label="SK" value="SK" />
       </Picker>
       </View>
       </View>
     )
     }
   };


export default class Contact extends React.Component {


  render() {
  return (
    <View style={styles.outer}>
      <View style={styles.container2}>
      <View style={styles.container3}>
      <Image
        style={{resizeMode: 'contain', width:'100%', height:width*0.95*0.38}}
        source={images['Map']}/>
      </View>
    </View>
    <View style={styles.container2}>
    <View style={styles.container3}>
<Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
Wayne Louks
{"\n"}
<Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
6-295 Queen Street East
{"\n"}
Suite 461
{"\n"}
Brampton, ON
{"\n"}
L6W 4S6
</Text>
</Text>
  </View>
  </View>
  <View style={styles.container2}>
  <View style={styles.container3}>
  <Text style={{margin:20, textAlignVertical:'center', fontWeight:'bold', fontSize: 22}}>
  Korey Louks
  {"\n"}
  <Text style={{textAlignVertical:'center', fontWeight:'normal', fontSize:16}}>
  6-295 Queen Street East
  {"\n"}
  Suite 461
  {"\n"}
  Brampton, ON
  {"\n"}
  L6W 4S6
  </Text>
  </Text>
  </View>
  </View>
  <View style={styles.container4}>
  <View style={styles.container5}>
  <FormM/>
  </View>
  </View>
      </View>
    )
}
}

const styles = StyleSheet.create({

  outer: {
    flex: 1,
    height:'100%',
    backgroundColor: '#fff',
    width:'100%',

  },
  container: {
    flex:1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
  },
  container2: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container3: {
    flexDirection:'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'95%',
  },
  container4: {
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
  },
  container5: {
    flexDirection:'column',
    backgroundColor: '#ffff',
    alignItems: 'center',
    justifyContent: 'center',
    width:'60%',
    padding:20,

  },
  space: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
    },
    input: {
    margin: 15,
    borderColor: 'black',
    borderWidth: 1,
  },
  input2: {
  margin: 15,
  borderColor: 'black',
  borderWidth: 1,
  lineHeight:50,
},
BtnCtn: {
    elevation: 8,
    borderRadius: 10,
    borderWidth:2,
    borderColor:'#ffff',
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor:'#000070',
    width:200,
    height:50,
  },
BtnText: {
    borderColor:'#000070',
    fontSize: 18,
    fontWeight: "normal",
    alignSelf: "center",
    textTransform: "uppercase",
    color:'#ffff'
  }
});

// export default Home;
